/** @jsx jsx */
import { jsx, Themed } from 'theme-ui';
import useContentfulVariables from "../hooks/useContentfulVariables";
import { matchAndReplace } from "../utils/utils";

const PageTitle = ({ children, ...other }) => {
  const contentfulVariables = useContentfulVariables();
  const title = matchAndReplace(children, contentfulVariables);

  return (
    <Themed.h1 {...other}>
      {title}
    </Themed.h1>
    );
};

export default PageTitle;
