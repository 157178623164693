import { useContext } from "react";
import { Themed } from "theme-ui";
import { LocalizationContext } from "../context/LocalizationContext";
import Link from "./Link";

const ReadMoreLinks = ({ links, language }) => {
  const { translate } = useContext(LocalizationContext);

  return (
    <Themed.div>
      <Themed.h3>{translate("contentHub.readMore", null, language)}</Themed.h3>
      <Themed.ul css={{ listStyle: "none", paddingLeft: 0 }}>
        {links.map(({ slug, title }) => {
          return (
            <li key={title}>
              <Link
                sx={{
                  display: "block",
                  py: 1,
                  whiteSpace: "nowrap",
                  color: "primary",
                  textDecoration: "underline",
                  padding: 0,
                  ":hover": { color: "#00A830", textDecoration: "none" },
                }}
                target={slug}
              >
                {title}
              </Link>
            </li>
          );
        })}
      </Themed.ul>
    </Themed.div>
  );
};

export default ReadMoreLinks;
