/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import Link from "./Link";

const TagLinks = ({ topics, themes, contentTypes, ...props }) => {
  topics = Array.isArray(topics) ? topics : topics && [topics];
  themes = Array.isArray(themes) ? themes : themes && [themes];
  contentTypes = Array.isArray(contentTypes) ? contentTypes : [contentTypes];

  const renderLink = (arr) => {
    if (!arr) return null;

    return arr.map((item) => {
      const { slug, parentPage } = item;
      const pageSlug = `/${parentPage.slug}/${slug}`;
      return (
        <Link
          key={`taglinks-${pageSlug}`}
          target={pageSlug}
          sx={{
            variant: "buttons.secondary",
            whiteSpace: "normal",
            mx: "0.2em",
            my: "0.2em",
            fontWeight: "normal"
          }}
        >
          {item.name ? item.name : item.theme}
        </Link>
      );
    });
  };

  return (
    <Box sx={{ mx: "-0.2em"}} {...props}>
      { [topics, contentTypes].map(item => renderLink(item)) }
      { [themes].map(item => renderLink(item) )}
    </Box>
  );
};

export default TagLinks;
