/** @jsx jsx */
import dayjs from "dayjs";
import { graphql } from "gatsby";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Box, Container, jsx, Themed } from "theme-ui";
import BreadCrumbs from "../components/BreadCrumbs";
import Layout from "../components/Layout";
import Like from "../components/Like";
import PageTitle from "../components/PageTitle";
import ReadMoreLinks from "../components/ReadMoreLinks";
import RichText2 from "../components/RichText2";
import SEO from "../components/SEO";
import Share from "../components/Share";
import TagLinks from "../components/TagLinks";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { useSitePages } from "../hooks/useSitePages";
import { sendLikeEvent } from "../utils/analytics";
import {
  createImageUrl,
  getLocalizedUrlAndTitleFromSlug,
  getUrlFromSlug,
} from "../utils/utils";

const ContentHubPageTemplate = ({ data, pageContext }) => {
  const nodes = useSitePages();
  const {
    pageType,
    title,
    navigationTitle,
    publishDate,
    metaKeywords,
    metaDescription,
    metaTitle,
    contentArea,
    headerImage,
    pageLike,
    sharePage,
    slug,
    contentType,
    theme,
    topic,
    hidden,
    noIndex,
    readMoreLinks,
  } = data.contentfulContentHubPage;

  const { defaultDescription, siteUrl, defaultImage, author } =
    useSiteMetadata();

  const { node_locale: locale, language, parentPage } = pageContext;

  const metadataTitle = metaTitle || title;

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;

  const metadataKeywords = metaKeywords ? metaKeywords : "";

  const metaImage =
    (headerImage &&
      headerImage.gatsbyImageData &&
      createImageUrl(headerImage)) ||
    siteUrl + defaultImage;

  const datePublished = new Date(+publishDate);
  const momentObj = dayjs(datePublished);
  const metadataDatePublished = momentObj.format("YYYY-MM-DD hh:mm:ssZ");
  const date = new Date(+publishDate);

  const { title: localizedNewsTitle, slug: localizedNewsSlug } =
    getLocalizedUrlAndTitleFromSlug(`/${parentPage.slug}`, language, nodes);

  const checkLikeStatus = (slug, likeType) => {
    let reactions = [];
    let savedReactions = JSON.parse(localStorage.getItem(likeType));
    if (Array.isArray(savedReactions)) {
      reactions = savedReactions;
    }
    if (reactions.indexOf(slug) === -1) {
      return false;
    } else {
      return true;
    }
  };

  let [like, setLike] = useState(null);
  let [dislike, setDislike] = useState(null);

  useEffect(() => {
    setLike(checkLikeStatus(slug, "PageLikes"));
    setDislike(checkLikeStatus(slug, "PageDislikes"));
  }, [slug]);

  const handleLike = () => {
    setLike(!like);
    if (dislike) {
      setDislike(!dislike);
      let dislikes = JSON.parse(localStorage.getItem("PageDislikes"));
      let filtered = dislikes.filter((dislike) => dislike !== slug);
      dislikes = filtered;
      localStorage.setItem("PageDislikes", JSON.stringify(dislikes));
    }
    // Get likes from local storage or use an empty array
    let likes = [];
    const storedLikes = JSON.parse(localStorage.getItem("PageLikes"));
    if (Array.isArray(storedLikes)) {
      likes = storedLikes;
    }
    // If page slug is not saved to local storage yet, push it to likes array and send like event
    if (likes.indexOf(slug) === -1) {
      likes.push(slug);
      sendLikeEvent("like");
      // If page is saved to local storage, user is removing a like. Remove slug from likes array
    } else {
      let filtered = likes.filter((like) => like !== slug);
      likes = filtered;
    }
    // Save final likes array to local storage
    localStorage.setItem("PageLikes", JSON.stringify(likes));
  };

  const handleDislike = () => {
    setDislike(!dislike);
    if (like) {
      setLike(!like);
      let likes = JSON.parse(localStorage.getItem("PageLikes"));
      let filtered = likes.filter((like) => like !== slug);
      likes = filtered;
      localStorage.setItem("PageLikes", JSON.stringify(likes));
    }
    // Get dislikes from local storage or use an empty array
    let dislikes = [];
    const stoderDislikes = JSON.parse(localStorage.getItem("PageDislikes"));
    if (Array.isArray(stoderDislikes)) {
      dislikes = stoderDislikes;
    }
    // If page slug is not saved to local storage yet, push it to likes array and send like event of dislike-type
    if (dislikes.indexOf(slug) === -1) {
      dislikes.push(slug);
      sendLikeEvent("dislike");
      // If page is saved to local storage, user is removing a dislike. Remove slug from likes array
    } else {
      let filtered = dislikes.filter((dislike) => dislike !== slug);
      dislikes = filtered;
    }
    // Save final dislikes array to local storage
    localStorage.setItem("PageDislikes", JSON.stringify(dislikes));
  };

  return (
    <Layout pageContext={pageContext} pageType={pageType}>
      <SEO
        lang={pageContext.language}
        title={metadataTitle}
        description={metadataDescription}
        keywords={metadataKeywords}
        image={metaImage}
        hidden={hidden}
        noIndex={noIndex}
        paths={pageContext.paths}
        metadataTitle={metadataTitle}
      />
      <Container>
        <BreadCrumbs
          slug={slug}
          navigationTitle={navigationTitle}
          parentPage={{
            slug: localizedNewsSlug,
            navigationTitle: localizedNewsTitle,
          }}
          language={language}
        />
      </Container>
      <Container variant="narrow" id="content" sx={{ py: [3, null, 5] }}>
        {headerImage && (
          <GatsbyImage image={headerImage.gatsbyImageData} alt={headerImage.description} />
        )}
        <PageTitle>{title}</PageTitle>
        <Themed.p
          sx={{
            mb: [3, 5],
          }}
        >
          {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
        </Themed.p>
        <RichText2 content={contentArea} locale={locale} language={language} />
        {readMoreLinks && <ReadMoreLinks links={readMoreLinks} />}
        <TagLinks
          topics={topic}
          themes={theme}
          contentTypes={contentType}
          sx={{ my: "3em" }}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {pageLike ? (
            <Like
              handleLike={handleLike}
              handleDislike={handleDislike}
              liked={like}
              disliked={dislike}
              language={language}
            />
          ) : (
            ""
          )}
          {sharePage ? <Share language={language} /> : ""}
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $aspectRatio: Float) {
    contentfulContentHubPage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      navigationTitle
      pageLike
      sharePage
      hidden
      noIndex
      headerImage {
        gatsbyImageData(aspectRatio: $aspectRatio, quality: 85, width: 1080)
        description
      }
      bigImage
      metaKeywords
      metaDescription {
        internal {
          content
        }
      }
      metaTitle
      publishDate(formatString: "x")
      contentArea {
        raw
        references {
          ... on Node {
            ...fragmentContentfulFrontpage
          }
          ... on Node {
            ...fragmentContentfulThemePage
          }
          ... on Node {
            ...fragmentContentfulProductPage
          }
          ... on Node {
            ...fragmentContentfulPage
          }
          ... on Node {
            ...fragmentContentfulContentHubThemePage
          }
          ... on Node {
            ...fragmentContentfulContentHubPage
          }
          ... on Node {
            ...fragmentContentfulContentHubContentTypePage
          }
          ... on Node {
            ...fragmentContentfulContentHubTopicPage
          }
          ... on Node {
            ...fragmentContentfulContentfulInsuranceTermsPage
          }
          ... on Node {
            ... on ContentfulInsuranceBrochure {
              id
              contentful_id
              __typename
              slug
            }
          }
          ... on Node {
            ...fragmentContentfulLiftUpColor
          }
          ...fragmentContentfulCallToAction
          ...fragmentContentfulComponent
          ...fragmentContentfulResponsiveIframe
          ...fragmentContentfulAccordion
          ...fragmentContentfulLiftUpBox
          ...fragmentContentfulAsset
          ...fragmentContentfulInlineImage
          ... on Node {
            ...fragmentContentfulDynamicSection
          }
        }
      }
      slug
      contentType {
        name
        node_locale
        slug
        parentPage {
          slug
        }
      }
      theme {
        theme
        slug
        parentPage {
          slug
        }
      }
      topic {
        name
        node_locale
        slug
        parentPage {
          slug
        }
      }
      readMoreLinks {
        ...fragmentContentfulThemePage
        ...fragmentContentfulPage
        ...fragmentContentfulContentHubPage
      }
    }
  }
`;
export default ContentHubPageTemplate;
