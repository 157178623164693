import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../context/LocalizationContext";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { SvgIcon } from "@material-ui/core";
import colors from "../gatsby-plugin-theme-ui/colors";

const useStyles = makeStyles(() => ({
  inactive: {
    color: "#333333",
    width: "80px",
    height: "80px",
    '&:hover': {
      background: colors.primary,
      color: "white"
    },
    '&:focus': {
      background: colors.primary,
      color: "white"
    },
  },
  activeLike: {
    color: "#008A28",
    width: "80px",
    height: "80px",
    '&:hover': {
      background: colors.primary,
      color: "white"
    },
  },
  activeDislike: {
    color: "#D10000",
    width: "80px",
    height: "80px",
    '&:hover': {
      background: colors.primary,
      color: "white"
    },
  },
  icon: {
    width: "100%",
    height: "100%",
  },
}));

const Like = (props) => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";

  useEffect(() => {
    setClient(true);
  }, []);

  const classes = useStyles();

  const { translate } = useContext(LocalizationContext);
  const language = props.language;

  const likeClass = props.liked ? classes.activeLike : classes.inactive;
  const dislikeClass = props.disliked
    ? classes.activeDislike
    : classes.inactive;

  if (!isClient) return null;
  return (
    <div key={key}>
      <p>{translate("like.title", null, language)}</p>
      <IconButton
        aria-label={
          translate("like.title", null, language) +
          " " +
          translate("like.yes", null, language)
        }
        onClick={props.handleLike}
        className={likeClass}
      >
        <SvgIcon
          component={"svg"}
          viewBox={"0 0 50 50"}
          className={classes.icon}
          titleAccess={translate("like.yes", null, language)}
        >
          <svg>
            <path d="M40.63,24.58a3.46,3.46,0,0,0,.69-2.07v-.63a3.5,3.5,0,0,0-3.5-3.5H32.18a3.41,3.41,0,0,0-2.16.77H25.59a7.64,7.64,0,0,1,.62-2.43c2.9-6,3.24-9.65,1.12-12.09a2.65,2.65,0,0,0-2.62-1c-1.4.3-2.62,1.8-3.33,4.13C20.8,9.6,19,12,17.39,14c-.8,1-1.55,2-2.16,2.92a9.45,9.45,0,0,1-3.13,3,.92.92,0,0,1-.38.08H6.28v2h5.44A2.81,2.81,0,0,0,13,21.78a11.3,11.3,0,0,0,3.92-3.72c.56-.85,1.3-1.8,2.07-2.8,1.79-2.32,3.64-4.72,4.32-6.94.54-1.75,1.33-2.65,1.83-2.76.08,0,.32-.07.7.38,1,1.15,1.75,3.32-1.41,9.9a11.28,11.28,0,0,0-.88,4.3v1h5.22a3.65,3.65,0,0,0-.08.74v.63a3.42,3.42,0,0,0,.55,1.87H29A3.59,3.59,0,0,0,25.36,28v.66a3.57,3.57,0,0,0,1.85,3.11A3.46,3.46,0,0,0,26.38,34v.63a3.49,3.49,0,0,0,2.22,3.25,3.49,3.49,0,0,0-.62,2v.64a3.45,3.45,0,0,0,.35,1.5H15.58c-2.06,0-3.75-1.29-3.75-2.88v-1H6.28v2H10A5.61,5.61,0,0,0,15.58,44H36.07a3.5,3.5,0,0,0,3.5-3.5v-.64a3.54,3.54,0,0,0-.57-1.9,3.5,3.5,0,0,0,2.47-3.33V34a3.48,3.48,0,0,0-.64-2,3.59,3.59,0,0,0,2.28-3.33V28A3.56,3.56,0,0,0,40.63,24.58Zm-10-2.7a1.5,1.5,0,0,1,1.5-1.5h5.64a1.5,1.5,0,0,1,1.5,1.5v.63a1.5,1.5,0,0,1-1.5,1.5H32.18a1.5,1.5,0,0,1-1.5-1.5Zm6.89,18.59a1.51,1.51,0,0,1-1.5,1.5H31.48a1.5,1.5,0,0,1-1.5-1.5v-.64a1.5,1.5,0,0,1,1.5-1.5h4.59a1.51,1.51,0,0,1,1.5,1.5Zm1.9-5.87A1.5,1.5,0,0,1,38,36.1H29.88a1.5,1.5,0,0,1-1.5-1.5V34a1.5,1.5,0,0,1,1.5-1.5H38a1.5,1.5,0,0,1,1.5,1.5Zm1.64-6a1.58,1.58,0,0,1-1.58,1.58H29a1.59,1.59,0,0,1-1.59-1.58V28A1.6,1.6,0,0,1,29,26.38H39.53A1.59,1.59,0,0,1,41.11,28Z" />
          </svg>
        </SvgIcon>
      </IconButton>
      <IconButton
        aria-label={
          translate("like.title", null, language) +
          " " +
          translate("like.no", null, language)
        }
        className={dislikeClass}
        onClick={props.handleDislike}
      >
        <SvgIcon
          component={"svg"}
          viewBox={"0 0 50 50"}
          className={classes.icon}
          titleAccess={translate("like.no", null, language)}
        >
          <svg>
            <path d="M8.76,22.94A3.45,3.45,0,0,0,8.07,25v.64a3.5,3.5,0,0,0,3.5,3.5h5.65a3.46,3.46,0,0,0,2.15-.77H23.8a7.68,7.68,0,0,1-.61,2.43c-2.91,6-3.25,9.65-1.13,12.08a2.67,2.67,0,0,0,2.62,1c1.4-.3,2.62-1.8,3.33-4.13.58-1.88,2.4-4.23,4-6.31.79-1,1.54-2,2.15-2.92a9.44,9.44,0,0,1,3.13-3,.79.79,0,0,1,.38-.08h5.44v-2H37.67a2.83,2.83,0,0,0-1.26.28,11.4,11.4,0,0,0-3.91,3.72c-.57.86-1.3,1.8-2.08,2.81C28.63,34.58,26.79,37,26.1,39.2c-.53,1.74-1.32,2.65-1.83,2.76-.08,0-.31.07-.7-.38-1-1.16-1.75-3.33,1.42-9.9a11.25,11.25,0,0,0,.87-4.31v-1H20.63a3,3,0,0,0,.09-.73V25a3.45,3.45,0,0,0-.56-1.87h.28A3.59,3.59,0,0,0,24,19.55v-.66a3.57,3.57,0,0,0-1.84-3.11A3.51,3.51,0,0,0,23,13.55v-.64a3.51,3.51,0,0,0-2.22-3.25,3.45,3.45,0,0,0,.62-2V7.05a3.45,3.45,0,0,0-.35-1.5H33.81c2.06,0,3.75,1.29,3.75,2.87v1h5.55v-2H39.44a5.62,5.62,0,0,0-5.63-3.87H13.33a3.5,3.5,0,0,0-3.5,3.5v.63a3.43,3.43,0,0,0,.57,1.9,3.5,3.5,0,0,0-2.48,3.33v.64a3.42,3.42,0,0,0,.65,2,3.57,3.57,0,0,0-2.29,3.33v.66A3.58,3.58,0,0,0,8.76,22.94Zm10,2.7a1.51,1.51,0,0,1-1.5,1.5H11.57a1.51,1.51,0,0,1-1.5-1.5V25a1.51,1.51,0,0,1,1.5-1.5h5.65a1.51,1.51,0,0,1,1.5,1.5ZM11.83,7.05a1.5,1.5,0,0,1,1.5-1.5h4.58a1.5,1.5,0,0,1,1.5,1.5v.63a1.5,1.5,0,0,1-1.5,1.5H13.33a1.5,1.5,0,0,1-1.5-1.5ZM9.92,12.91a1.5,1.5,0,0,1,1.5-1.5h8.09a1.5,1.5,0,0,1,1.5,1.5v.64a1.5,1.5,0,0,1-1.5,1.5H11.42a1.51,1.51,0,0,1-1.5-1.5Zm-1.64,6A1.59,1.59,0,0,1,9.87,17.3H20.44A1.59,1.59,0,0,1,22,18.89v.66a1.59,1.59,0,0,1-1.59,1.58H9.87a1.59,1.59,0,0,1-1.59-1.58Z" />
          </svg>
        </SvgIcon>
      </IconButton>
    </div>
  );
};

export default Like;
